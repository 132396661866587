import React, { useContext, useEffect, useRef, useState } from "react";
import { CommonServiceContext } from "../../config/commonContext";
import Model from "../model/model";
import { ModelServiceContext } from "../../config/modelContext";
import { HttpServiceContext } from "../../config/httpContext";
import { useNavigate } from "react-router-dom";

const Iframe = ({ closeIframe, active, label, visioMaps, ref }) => {
  const [Tab, setTab] = useState("2D");
  const commonContext = useContext(CommonServiceContext);
  const [visioMap, setVisioMap] = useState(false);
  const modelContext = useContext(ModelServiceContext);
  const [show3dView, setShow3dView] = useState(false);
  const [buildingId, setBuildingId] = useState();
  const httpContext = useContext(HttpServiceContext);
  const nav = useNavigate();

  const visio = useRef();
  const model = useRef();
  useEffect(() => {
    if (active) setTab(active);
  }, [active]);
  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;
      if (typeof data === "string") {
        setBuildingId(data);
      }
      if (typeof data === "object") {
        if (data.message === "Launch Digital Twin") {
          setShow3dView(true);
        }

        if (data.message === "Show AHU") {
          commonContext.setShowAssetAnalytics(true);
        }
        if (data.message === "navigate T07") {
          setTimeout(() => {
            nav("/alarm", { state: { key: "T07" } });
          }, 2000);
        }
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    commonContext.setBId(buildingId);
    let intervalId;
    poolDeviceState();
    intervalId = setInterval(() => {
      poolDeviceState();
    }, 1000);
    return () => clearInterval(intervalId);
    // } else {
    //   commonContext.setAhuIncident(false);
    //   sendMessageToModelIframe({ state: false });
    //   commonContext.ahutemperaturedata(2200, 2800);
    // }
  }, [buildingId]);

  const sendMessageToIframe = () => {
    const msgdata = {
      buildingID: "BT07",
      floorID: "BT07-UL0",
      mode: "floor",
    };
    visio?.current?.contentWindow.postMessage(msgdata, "*");
  };

  const sendMessageToModelIframe = (msgdata) => {
    model?.current?.contentWindow.postMessage(msgdata, "*");
    visio?.current?.contentWindow.postMessage(msgdata, "*");
  };

  const poolDeviceState = () => {
    httpContext.getDeviceState().then((data) => {
      if (data["SIM-003"].state === true) {
        commonContext.setAhuIncident(true);
        sendMessageToModelIframe({ ahustate: true });
        commonContext.ahutemperaturedata(2800, 3200);
        modelContext.setAhuIncident(true);
      } else {
        commonContext.setAhuIncident(false);
        sendMessageToModelIframe({ ahustate: false });
        commonContext.ahutemperaturedata(2200, 2800);
        modelContext.setAhuIncident(false);
      }
      if (data["SIM-009"].state === true) {
        commonContext.setBearingIncident(true);
        sendMessageToModelIframe({ bearingState: true });
      } else {
        commonContext.setBearingIncident(false);
        sendMessageToModelIframe({ bearingState: false });
      }
    });
  };
  return (
    <div>
      {label === "traffic" && (
        <div className="p-2">
          <div className="tabs">
            <div className="tabOptions">
              <div>
                <button onClick={() => setTab("3D")} className={`tabBtns button-if ${Tab === "3D" ? "active" : ""}`}>
                  Live Traffic
                </button>
              </div>
              <div>
                <button onClick={() => setTab("2D")} className={`tabBtns button-if ${Tab === "2D" ? "active" : ""}`}>
                  Scenario Analysis
                </button>
              </div>

              <div>
                <button onClick={() => setTab("~")} className={`tabBtns  button-if ${Tab === "~" ? "active" : ""}`}>
                  Congestion Hotspot
                </button>
              </div>
              <div className="minimize mt-1">
                <img src="/minimize-s.png" className="mini-traf" onClick={closeIframe} alt=""></img>
              </div>
            </div>
          </div>
          <div>
            {Tab === "2D" && <iframe className="iframeMaxHeight mt-0" src={httpContext.trafficIframeURL} title="traffic"></iframe>}
            {Tab === "3D" && <iframe className="iframeMaxHeight mt-0" src={commonContext.traffic3DIframeURL} title="traffic"></iframe>}
            {Tab === "~" && <iframe className="iframeMaxHeight mt-0" src={commonContext.trafficdeckglIframeURL} title="traffic"></iframe>}
          </div>
        </div>
      )}
      {label === "bms" && (
        <div className="frameWindow w-100 p-2">
          <div className="minimize mb-4">
            <img src="/minimize-s.png" className="mini-traf" onClick={closeIframe} alt=""></img>
          </div>
          <div>
            {!show3dView && (
              <iframe className="iframeMaxHeight" src={`${visioMaps ? httpContext.visioWebURL : commonContext.gmapURL}`} title="visioWeb"></iframe>
            )}
            {show3dView && (
              <div className="iframeMaxHeight position-relative">
                <Model></Model>
              </div>
            )}
          </div>
        </div>
      )}
      {label === "overviewMap" && (
        <div className="frameWindow w-100 p-2">
          <div className="minimize mb-4">
            <img src="/minimize-s.png" className="mini-traf" onClick={closeIframe} alt=""></img>
          </div>
          <div>
            {!show3dView && (
              <iframe
                ref={visio}
                className="iframeMaxHeight"
                src={`${visioMap ? httpContext.visioWebURL : commonContext.gmapURL}`}
                title="visioWeb"
              ></iframe>
            )}
            {show3dView && <iframe ref={model} className="iframeMaxHeight" src={commonContext.modelURL} title="3d Model"></iframe>}
            {/* <iframe className="iframeMaxHeight" src={commonContext.gmapURL} title="3d Map"></iframe> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Iframe;
