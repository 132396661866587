import React, { useContext, useEffect, useState } from "react";
import { Card, Button, Form, CardHeader } from "react-bootstrap";
import "../model/serverPanel.scss"; // Import the external CSS
import LineChart from "../echarts/LineChartDynamic";
import subsystems from "../../assets/subsystem.json";
import { HttpServiceContext } from "../../config/httpContext";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-bootstrap/Modal";
import info from "../../assets/icons/info.svg";

const ServerPanel = () => {
  const [checked, setChecked] = useState({
    CRAC1: [],
    CRAC2: [],
    CRAC3: [],
    CRAC4: [],
  });

  const [fs, setFs] = useState(6);
  const [load, setLoad] = useState([]);
  const [loader, setLoader] = useState(true);
  const [requestId, setRequestId] = useState();
  const [chartData, setChartData] = useState([]);
  const [xlabel, setXlabel] = useState([]);
  const [showModel, setShowModel] = useState(false);

  const httpContext = useContext(HttpServiceContext);

  const chartStyle = {
    height: "32vh",
    width: "100%",
  };

  useEffect(() => {
    getDcTemperatureData();
  }, []);

  useEffect(() => {
    if (fs) {
      const newFields = Array(fs).fill(5);
      const newCRACFields = Array(fs).fill(0);
      setLoad(newFields);
      setChecked({ CRAC1: newCRACFields, CRAC2: newCRACFields, CRAC3: newCRACFields, CRAC4: newCRACFields });
    }
  }, [fs]);

  const handleSwitchChange = (e, cracIndex, switchIndex) => {
    const { checked: switchChecked } = e.target;
    const updatedCRAC = [...checked[cracIndex]];
    updatedCRAC[switchIndex] = switchChecked ? 1 : 0;
    console.log(checked, updatedCRAC);
    setChecked((prevState) => ({
      ...prevState,
      [cracIndex]: updatedCRAC,
    }));
  };

  const handleInputChange = (value, name) => {
    const numericValue = Number(value);
    setFs(numericValue);
  };

  const handleForecastChange = (index, value) => {
    const numericValue = Number(value);
    const updatedFields = [...load];
    updatedFields[index] = numericValue;
    setLoad(updatedFields);
  };
  function generateRandomNumber(digits) {
    const min = Math.pow(10, digits - 1);
    const max = Math.pow(10, digits) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleSubmit = (e) => {
    const randomNumber = generateRandomNumber(5);
    let payload = {
      inputs: {
        request_Id: randomNumber,
        forecast_steps: fs,
        regressors: {
          IT_Load_Binned_Num: load,
          CRAC1_Status: checked.CRAC1,
          CRAC2_Status: checked.CRAC2,
          CRAC3_Status: checked.CRAC3,
          CRAC4_Status: checked.CRAC4,
        },
      },
    };
    setRequestId(randomNumber);
    console.log(payload);
    let dt = httpContext.simulateDcModel(payload);
    if (dt) {
      console.log(dt);
      setLoader(true);
      const intervalId = setInterval(async () => {
        await getDcTemperatureData(intervalId, randomNumber);
      }, 1000);
    }
  };
  async function getDcTemperatureData(intervalId, randomNumber) {
    let dt = await httpContext.getDcTemperatureData(randomNumber ?? requestId);
    if (dt.data.xData.length) {
      setChartData(dt.data.temp);
      setXlabel(dt.data.xData);
      setLoader(false);

      if (intervalId) clearInterval(intervalId);
    }
  }
  function closeModel() {
    setShowModel(false);
  }

  return (
    <div>
      <Card
        className="card-custom"
        style={{
          background: "linear-gradient(180deg, hsla(202, 87%, 53%, 0.16) 0%, hsla(237, 61%, 60%, 0.16) 100%)",
        }}
      >
        <Card.Body>
          <Form>
            <Form.Group className="forecast d-flex justify-content-between ">
              <div className="text-light fw-bold">Forecast Step (5 min) : </div>
              <div>
                <input
                  type="number"
                  placeholder="Enter IT Load"
                  className="w-100"
                  name="input1"
                  value={fs}
                  onChange={(e) => handleInputChange(e.target.value, e.target.name)}
                  min="0"
                  max="30"
                  step="1"
                />
              </div>
            </Form.Group>
            <Form.Group className="it-load">
              <div className="text-light fw-bold pb-1">IT Load (in %) : </div>
              <div className="d-flex flex-nowrap">
                {load.map((value, index) => (
                  <div className="p-1 ">
                    <input
                      key={index}
                      type="text"
                      placeholder={`Forecast Step ${index + 1}`}
                      className="w-100 mb-2"
                      value={value ? value * 10 : value}
                      onChange={(e) => handleForecastChange(index, e.target.value)}
                    />
                  </div>
                ))}
              </div>
            </Form.Group>
            {["CRAC1", "CRAC2", "CRAC3", "CRAC4"].map((crac, cracIndex) => (
              <Form.Group controlId={`switch${crac}`} className="form-group-custom" key={crac}>
                <Form.Label className="craclabel">{crac}</Form.Label>
                <div className="d-flex flex-nowrap">
                  {load.map((_, switchIndex) => (
                    <div className="p-1" key={switchIndex}>
                      <Form.Check
                        type="switch"
                        name={`${crac}${switchIndex}`}
                        checked={checked[crac][switchIndex]}
                        onChange={(e) => handleSwitchChange(e, crac, switchIndex)}
                      />
                    </div>
                  ))}
                </div>
              </Form.Group>
            ))}

            <div className="simulate">
              <Button className="btn bttn" onClick={handleSubmit}>
                Simulate
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <Card
        className="card-custom"
        style={{
          background: "linear-gradient(180deg, hsla(202, 87%, 53%, 0.16) 0%, hsla(237, 61%, 60%, 0.16) 100%)",
        }}
      >
        <Card.Body>
          <div>
            <div className="d-flex justify-content-end">
              <div className="info cursor-pointer" onClick={() => setShowModel(true)}>
                <img src={info} alt="Logo" height="20px" width="20px" />
              </div>
            </div>
            <div className="position-absolute z-2 puffloader">
              <ClipLoader className="mx-auto" size={50} color="white" loading={loader} />
            </div>
            {subsystems.bms.dc.map((ch) => {
              return <LineChart data={ch} style={chartStyle} cData={chartData} xData={xlabel}></LineChart>;
            })}
          </div>
        </Card.Body>
      </Card>
      <Modal show={showModel} onHide={() => closeModel()} className="w-10" centered>
        <Modal.Header className="justify-content-end">
          <Modal.Title>
            <div className="">
              <button type="button" className="close btn bttn" onClick={() => closeModel()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3">
            <div className="text-center p-20px">Model performance metrics:</div>
            <div className="d-flex justify-content-between align-self-center">
              <div>MSE : 0.08128181494832083</div>
              <div>RMSE : 0.285099657923893</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center justify-content-center"></Modal.Footer>
      </Modal>
    </div>
  );
};

export default ServerPanel;
