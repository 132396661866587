/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import DEVICE_STATE from "./../../assets/device_state.json";
import { HttpServiceContext } from "../../config/httpContext";
import { CommonServiceContext } from "../../config/commonContext";

function Simulation() {
  const [consoleMessages, setConsoleMessages] = useState([
    "API update failed!",
    "Response TypeError: Failed to fetch",
    "API update failed!",
    "Response TypeError: Failed to fetch",
    "Updating API(POST)...",
    "API Updated!",
    "Updating API(POST)...",
  ]);
  const httpContext = useContext(HttpServiceContext);
  const commonContext = useContext(CommonServiceContext);
  const [defaultData, setDefaultData] = useState(DEVICE_STATE);

  const [deviceData, setDeviceData] = useState({});
  const [url, setUrl] = useState(httpContext.simulationUrl);

  useEffect(() => {
    httpContext.setSimulationUrl(url);
    let intervalId = setInterval(async () => {
      updateDeviceState();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  async function updateDeviceState() {
    let data = await httpContext.getDeviceState();
    if (data) {
      setDeviceData(data);
      commonContext.setDevice_State({ ...data });
    } else {
      setDeviceData(defaultData);
    }
  }

  const updateConsoleMessages = (newMessages) => {
    setConsoleMessages((prevMessages) => {
      const updatedMessages = prevMessages.slice(1);
      return [...updatedMessages, newMessages];
    });
  };

  const handleDeviceData = async (data, deviceKey, newState) => {
    setDeviceData((prevData) => ({
      ...prevData,
      [deviceKey]: {
        ...prevData[deviceKey],
        state: newState,
      },
    }));
    const clonedData = JSON.parse(JSON.stringify(data));
    clonedData[deviceKey].state = newState;
    updateConsoleMessages("Updating API(POST)... ");
    let updatedData = await httpContext.setDeviceState(clonedData);
    if (updatedData.error) updateConsoleMessages("");
    else updateConsoleMessages("API Updated!");
  };

  function forceResetData() {
    updateConsoleMessages("Updating API(POST)...");
    const resetData = JSON.parse(JSON.stringify(defaultData));
    setDeviceData(resetData);
    let updatedData = httpContext.setDeviceState(resetData);
    if (updatedData.error) updateConsoleMessages("");
    else updateConsoleMessages("Workflow has been reset!");
  }

  async function resetDb() {
    await httpContext.updateTrafficDates();
  }

  return (
    <div className="simulation-control fc-white">
      <div className="text-center pt-3">
        <h2>Simulation Controls</h2>
        <div className="w-100 d-flex justify-content-between p-3 px-5 ">
          {/* <button className="btn bttn d-flex align-items-center">
            <img className="p-1" src={require("../../assets/images/link-circle.svg").default} alt=""></img>Configure
          </button> */}
          <div className="fw-bold">
            API Url : &nbsp;
            <input
              className="background-primary input-primary w-15vw fc-white input-image"
              value={url}
              type="text"
              onChange={(e) => setUrl(e.target.value)}
            ></input>
            <span className="p-1 px-3">
              <button className="btn bttn" onClick={(e) => httpContext.setSimulationUrl(url)}>
                Update
              </button>
            </span>
          </div>
          <div className="d-flex">
            <button className="btn bttn d-flex align-items-center mx-3" onClick={resetDb}>
              Update DB
            </button>
            <button className="btn bttn d-flex align-items-center" onClick={forceResetData}>
              Force Reset API
            </button>
          </div>
        </div>
      </div>
      <div className="background-primary m-3 mx-5 simulation-option-list rounded-3">
        <div className="p-2">
          {Object.entries(deviceData)?.map((device, index) => {
            return (
              <div className="row background-primary m-2 align-items-center p-2 rounded-3" key={index}>
                <div className="col-1">{device[0]}</div>
                <div className="col-2">{device[1].subsystemName}</div>
                <div className="col-5">
                  <div className="fs-6 fw-bold">{device[1].title}</div>
                  <div>{device[1].description}</div>
                </div>
                <div className="col-2">
                  <button
                    className={`btn bttn w-75  ${device[1].state === true ? "anomaly-button" : "unselected-button"}`}
                    onClick={() => handleDeviceData(deviceData, device[0], true)}
                  >
                    {device[1].trueButtonLabel}
                  </button>
                </div>
                <div className="col-2">
                  <button
                    className={`btn bttn w-75 ${device[1].state === false ? "normal-button" : "unselected-button"}`}
                    onClick={() => handleDeviceData(deviceData, device[0], false)}
                  >
                    {device[1].falseButtonLabel}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="background-primary m-2 mx-5 rounded-3">
        <div className="p-2">
          <div className="fw-bold">Console Updates:</div>
          <div className="fs-7 p-1">
            {consoleMessages.map((msg) => (
              <div>{msg}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Simulation;
