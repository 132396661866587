/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import AdvisoryModel from "./advisoryModel";
import { HttpServiceContext } from "../../config/httpContext";
import PieChart from "../echarts/pieChart";
import BarChart from "../echarts/bar-chart";
import AreaChart from "../echarts/area";
import LineHighChart from "../highcharts/lineHighCharts";
import subsystems from "../../assets/subsystem.json";
import Forecast from "../bms/forecast";
import { CommonServiceContext } from "../../config/commonContext";
import Buildingdata from "../../assets/building.json";
import DougnutChart from "../echarts/dougnutChart";
import GaugeChart from "../echarts/gaugeChart";

function Dashboardtabs({ closeWindow, active, buildingId, label }) {
  const [data, setData] = useState();
  const [showIssue, setShowIssue] = useState("");
  const [isForecast, setIsForecast] = useState(false);
  const [activeTab, setActiveTab] = useState("analytics");
  const [showAdv, setShowAdv] = useState(false);
  const commonContext = useContext(CommonServiceContext);
  const httpContext = useContext(HttpServiceContext);
  const visio = useRef();

  useEffect(() => {
    async function getData() {
      let mydata = await httpContext.getData();
      if (mydata) {
        setData(mydata.data);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (active) setActiveTab(active);
  }, [active]);

  const chartStyle = {
    height: "85vh",
    width: " 100%",
  };

  const handleSop = (event, id) => {
    setShowAdv(true);
    const advEntry = data.advisory.bms.find((adv) => adv.id === id);
    if (advEntry) {
      setShowIssue(advEntry);
    }
  };

  function sopTab() {
    setActiveTab("Incidents");
    setShowAdv(false);
  }

  const sendMessageToIframe = () => {
    const msgdata = {
      buildingID: "BT07",
      floorID: "BT07-UL0",
      mode: "floor",
    };
    visio?.current?.contentWindow.postMessage(msgdata, "*");
  };
  useEffect(() => {
    if (label === "AccidentTabs") {
      setActiveTab("Incidents");
    }
  }, [label]);
  const limitedData = data && data.advisory && Array.isArray(data.advisory.bms) ? data.advisory.bms.slice(0, 6) : [];
  const bearingData = data && data.advisory && Array.isArray(data.advisory.bms) ? data.advisory.bms.slice(1, 2) : [];

  return (
    <div>
      {label === "overviewTabs" && (
        <div className="frameWindow w-100">
          <div>
            <div className="tabs">
              <div className="tabOptions mb-2">
                <div>
                  <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                    Incidents
                  </button>
                </div>
                <div>
                  <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                    Analytics
                  </button>
                </div>
                <div>
                  <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                    Advisory
                  </button>
                </div>

                <div className="minimize mt-2">
                  <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow} alt=""></img>
                </div>
              </div>
              <div className="tabContent">
                {activeTab === "analytics" && (
                  <div className="row">
                    {activeTab === "analytics" && (
                      <div className="row">
                        {subsystems?.bms?.overview?.leftpanel.map((item) => {
                          if (item.type === "Img") {
                            if (!buildingId) {
                              const defaultImageSrc = require("../../assets/images/green.png");
                              const overall = 46;
                              return (
                                <div className="col-4 chart-container mt-2">
                                  <div className="leedTitle py-1 px-2">{item.title}</div>
                                  <div className="pt-4 pb-1  ">
                                    <img src={defaultImageSrc} alt="Default LEED score" className="certifyLeedMax" />
                                  </div>
                                  <div className="leedScoreMax  p-2">Score: {overall}</div>
                                </div>
                              );
                            }

                            for (let i = 0; i < Buildingdata.length; i++) {
                              const details = Buildingdata[i];
                              if (details.id === buildingId) {
                                const imageSrc =
                                  details.leedScore >= 40 && details.leedScore <= 49
                                    ? require("../../assets/images/green.png")
                                    : details.leedScore >= 50 && details.leedScore <= 59
                                    ? require("../../assets/images/silver.png")
                                    : details.leedScore >= 60 && details.leedScore <= 79
                                    ? require("../../assets/images/gold.png")
                                    : details.leedScore >= 80
                                    ? require("../../assets/images/platinum.png")
                                    : require("../../assets/images/green.png");

                                return (
                                  <div className="col-4 chart-container pt-2" key={details.id}>
                                    <div className="leedTitle py-1 px-2">{item.title}</div>
                                    <div className="pt-4">
                                      <img src={imageSrc} alt={`LEED score ${details.leedScore}`} className="certifyLeedMax" />
                                    </div>
                                    <div className="leedScoreMax p-2 ">Score: {details.leedScore}</div>
                                  </div>
                                );
                              }
                            }

                            return null;
                          } else if (item.type === "pie") {
                            return (
                              <div className="col-4" key={item.id}>
                                <DougnutChart data={item} style={chartStyle} buildingId={buildingId} />
                              </div>
                            );
                          } else if (item.type === "gauge") {
                            return (
                              <div className="col-4" key={item.id}>
                                <GaugeChart data={item} style={chartStyle} buildingId={buildingId} />
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}
                  </div>
                )}
                {activeTab === "advisory" && (
                  <div className="p-1">
                    {buildingId === "T07" ? (
                      <table className="d-block text-light table px-0" style={{ height: "29vh" }}>
                        <tr className="d-flex justify-content-between  align-items-baseline pr_1rem ">
                          <th className="col-11 fs-6 px-4">Observation</th>
                          <th className="col-auto px-0 fs-6">Action</th>
                        </tr>
                        {limitedData.map((adv, i) => {
                          if (i === 0 && !commonContext.ahuIncident) {
                            return null;
                          }
                          return (
                            <tr key={adv.id} className="d-flex justify-content-between px-4">
                              <td className="col-10 text-justify py-1 border-0 text fs-7">
                                {adv.observation}
                                <span
                                  className="span-color"
                                  onClick={() => {
                                    sendMessageToIframe();
                                  }}
                                >
                                  {adv?.assetId}
                                </span>
                              </td>
                              <td>
                                <button
                                  key={adv.id}
                                  className="col-auto myBtns text-white cursor-pointer"
                                  onClick={(event) => handleSop(event, adv.id)}
                                >
                                  ACT
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    ) : (
                      <table className="d-block text-light table px-0 advtable">
                        <tr className="d-flex justify-content-between  align-items-baseline pr_1rem ">
                          <th className="col-11 fs-6 px-4">Observation</th>
                          <th className="col-auto px-0 fs-6">Action</th>
                        </tr>
                        {data &&
                          data.advisory &&
                          data.advisory.bms.map((adv, i) => {
                            if (i === 0 && !commonContext.ahuIncident) {
                              return null;
                            }
                            return (
                              <tr key={adv.id} className="d-flex justify-content-between px-4">
                                <td className="col-10 text-justify py-1 border-0 text fs-7">
                                  {adv.observation}
                                  <span
                                    className="span-color"
                                    onClick={() => {
                                      sendMessageToIframe();
                                    }}
                                  >
                                    {adv?.assetId}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    key={adv.id}
                                    className=" col-auto myBtns text-white cursor-pointer"
                                    onClick={(event) => handleSop(event, adv.id)}
                                  >
                                    ACT
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </table>
                    )}
                  </div>
                )}
                {activeTab === "Incidents" && (
                  <div className="d-block mx-2">
                    <iframe className="iframetabs" src={httpContext.sop} title="Sop"></iframe>
                  </div>
                )}
              </div>
              {isForecast && <Forecast setforecast={() => setIsForecast(false)}></Forecast>}
              {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
            </div>
          </div>
        </div>
      )}
      {label === "BmsTabs" && (
        <div className="frameWindow w-100">
          <div>
            <div className="tabs">
              <div className="tabOptions mb-2">
                <div>
                  <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                    Incidents
                  </button>
                </div>
                <div>
                  <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                    Analytics
                  </button>
                </div>
                <div>
                  <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                    Advisory
                  </button>
                </div>

                <div className="minimize mt-2">
                  <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow} alt=""></img>
                </div>
              </div>
              <div className="tabContent">
                {activeTab === "analytics" && (
                  <div className="row">
                    {subsystems?.bms?.alarmOverview?.leftpanel?.map((chData) =>
                      chData.chartType === "pie" ? (
                        <div className="col-4">
                          <PieChart buildingId={buildingId} data={chData} style={chartStyle} />
                        </div>
                      ) : (
                        <div className="col-4">
                          <BarChart buildingId={buildingId} data={chData} style={chartStyle} />
                        </div>
                      )
                    )}
                  </div>
                )}
                {activeTab === "advisory" && (
                  <div className="p-1">
                    {buildingId === "T07" ? (
                      <table className="d-block text-light table px-0  " style={{ height: "29vh" }}>
                        <tr className="d-flex justify-content-between  align-items-baseline pr_1rem ">
                          <th className="col-11 fs-6 ">Observation</th>
                          <th className="col-auto px-0 fs-6">Action</th>
                        </tr>
                        {limitedData.map((adv, i) => {
                          if (i === 0 && !commonContext.ahuIncident) {
                            return null;
                          }
                          return (
                            <tr key={adv.id} className="d-flex justify-content-between px-4">
                              <td className="col-10 text-justify py-1 border-0 text fs-7">
                                {adv.observation}
                                <span
                                  className="span-color"
                                  onClick={() => {
                                    sendMessageToIframe();
                                  }}
                                >
                                  {adv?.assetId}
                                </span>
                              </td>
                              <td>
                                <button
                                  key={adv.id}
                                  className="col-auto myBtns text-white cursor-pointer"
                                  onClick={(event) => handleSop(event, adv.id)}
                                >
                                  ACT
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    ) : (
                      <table className="d-block text-light table px-0 advtable">
                        <tr className="d-flex justify-content-between  align-items-baseline pr_1rem">
                          <th className="col-11 fs-6 px-4">Observation</th>
                          <th className="col-auto px-0 fs-6">Action</th>
                        </tr>
                        {data &&
                          data.advisory &&
                          data.advisory.bms.map((adv, i) => {
                            if (i === 0 && !commonContext.ahuIncident) {
                              return null;
                            }
                            return (
                              <tr key={adv.id} className="d-flex justify-content-between px-4">
                                <td className="col-10 text-justify py-1 border-0 text fs-7">
                                  {adv.observation}
                                  <span
                                    className="span-color"
                                    onClick={() => {
                                      sendMessageToIframe();
                                    }}
                                  >
                                    {adv?.assetId}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    key={adv.id}
                                    className=" col-auto myBtns text-white cursor-pointer"
                                    onClick={(event) => handleSop(event, adv.id)}
                                  >
                                    ACT
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </table>
                    )}
                  </div>
                )}
                {activeTab === "Incidents" && (
                  <div className="d-block mx-2">
                    <iframe className="iframetabs" src={httpContext.sop} title="Sop"></iframe>
                  </div>
                )}
              </div>
              {isForecast && <Forecast setforecast={() => setIsForecast(false)}></Forecast>}
              {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
            </div>
          </div>
        </div>
      )}
      {label === "trafficTabs" && (
        <div className="tabs">
          <div className="tabOptions">
            <div>
              <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                Incidents
              </button>
            </div>
            <div>
              <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                Analytics
              </button>
            </div>
            <div>
              <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                Advisory
              </button>
            </div>
            <div className="minimize mt-2">
              <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow} alt=""></img>
            </div>
          </div>
          <div className="tabContent">
            {activeTab === "analytics" && (
              <div className="row">
                {subsystems.traffic.charts.leftpanel.map((chData) =>
                  chData.chartType === "pie" ? (
                    <div className="col-4">
                      <PieChart data={chData} style={chartStyle} />
                    </div>
                  ) : chData.chartType === "area" ? (
                    <div className="col-4">
                      <AreaChart data={chData} style={chartStyle} />
                    </div>
                  ) : (
                    <div className="col-4">
                      <BarChart data={chData} style={chartStyle} />
                    </div>
                  )
                )}
              </div>
            )}
            {activeTab === "advisory" && (
              <table className="d-block text-light table px-0 advtable">
                <tr className="d-flex justify-content-between  align-items-baseline pr_1rem ">
                  <th className="col-11 fs-6 px-4">Observation</th>
                  <th className="col-auto px-3 fs-6">Action</th>
                </tr>
                {data &&
                  data.advisory &&
                  data.advisory.traffic.map((adv) => (
                    <tr key={adv.id} className="d-flex justify-content-between px-4">
                      <td className="col-9 text-justify py-1 border-0 text fs-7">{adv.observation}</td>
                      <td>
                        <button key={adv.id} className=" col-auto myBtns text-white cursor-pointer" onClick={(event) => handleSop(event, adv.id)}>
                          ACT
                        </button>
                      </td>
                    </tr>
                  ))}
              </table>
            )}
            {activeTab === "Incidents" && (
              <div className="d-block mx-2">
                <iframe src={httpContext.sop} title="Sop" className="iframetabs"></iframe>
              </div>
            )}
          </div>
        </div>
      )}

      {label === "ahuTabs" && (
        <div className="frameWindow w-100">
          <div className="tabs">
            <div className="tabOptions mb-2">
              <div>
                <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                  Incidents
                </button>
              </div>
              <div>
                <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                  Analytics
                </button>
              </div>
              <div>
                <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                  Advisory
                </button>
              </div>
              <div>
                <button onClick={() => setActiveTab("realtime")} className={`tabBtns ${activeTab === "realtime" ? "active" : null}`}>
                  Realtime
                </button>
              </div>

              <div className="minimize mt-2">
                <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow} alt=""></img>
              </div>
            </div>
            <div className="tabContent ">
              {activeTab === "analytics" && (
                <div className="row">
                  {subsystems.bms.ahuanalytics.leftpanel.map((cd) => (
                    <div className="col-4">
                      <LineHighChart data={cd} style={chartStyle}></LineHighChart>
                    </div>
                  ))}
                </div>
              )}
              {activeTab === "advisory" && (
                <div className="p-1 ">
                  <table className="d-block text-light table px-0" style={{ height: "90vh" }}>
                    <tr className="d-flex justify-content-between  align-items-baseline pr_1rem ">
                      <th className="col-11 fs-6 px-4">Observation</th>
                      <th className="col-auto px-0 fs-6">Action</th>
                    </tr>
                    {limitedData.map(
                      (adv, i) =>
                        commonContext.ahuIncident && (
                          <tr key={adv.id} className="d-flex justify-content-between px-4">
                            <td className="col-10 text-justify py-1 border-0 text fs-7">
                              {adv.observation}
                              <span className="span-color">{adv?.assetId}</span>
                            </td>
                            <td>
                              <button
                                key={adv.id}
                                className=" col-auto myBtns text-white cursor-pointer"
                                onClick={(event) => handleSop(event, adv.id)}
                              >
                                ACT
                              </button>
                            </td>
                          </tr>
                        )
                    )}
                  </table>
                </div>
              )}
              {activeTab === "Incidents" && (
                <div style={{ height: "90vh" }}>
                  <iframe src={httpContext.sop} title="Sop" style={{ height: "100%", width: "100%" }}></iframe>
                </div>
              )}
              {activeTab === "realtime" && (
                <div className=" realtimeCard p-1" style={{ height: "90vh" }}>
                  {subsystems.bms.ahuanalytics.realTimeData.description.map((item, index) => (
                    <div key={index} className="text-light fs-7 text-center m-2 realtimeContent p-1">
                      <div className="fs-5 fw-bold  mt-4 p-4">{item.title}</div>
                      <div className="row">
                        {item.details.map((val, idx) => (
                          <div key={idx} className="col-6 mt-3">
                            <div>
                              <span className="fs-5 fw-bold">{val.title} : </span>
                              <span className="fs-6">
                                &nbsp;
                                {val.value ? (
                                  val.value === "random" ? (
                                    <input
                                      className={`cursor-pointer ${httpContext.incident ? "randomRat" : "random-none"}`}
                                      id="RAT"
                                      readOnly
                                      value={commonContext.inputFieldValue}
                                    />
                                  ) : (
                                    val.value
                                  )
                                ) : null}
                              </span>
                            </div>

                            {val.details && val.details.length > 0 && (
                              <div className="nested-details d-flex flex-row mx-2 mt-4">
                                {val.details.map((nestedVal, nestedIdx) => (
                                  <div key={nestedIdx} className="d-flex flex-column col-6 ">
                                    <div className="d-flex flex-column mx-2 ">
                                      <div className="fs-5 fw-bold">{nestedVal.title}</div>
                                      <div className="fs-6">
                                        &nbsp;
                                        {nestedVal.value ? (
                                          nestedVal.value === "random" ? (
                                            <input
                                              className={`cursor-pointer ${httpContext.incident ? "randomRat" : "random-none"}`}
                                              id="RAT"
                                              readOnly
                                              value={commonContext.inputFieldValue}
                                            />
                                          ) : (
                                            nestedVal.value
                                          )
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
            </div>
          </div>
        </div>
      )}
      {label === "ViolationTabs" && (
        <div className="tabs">
          <div className="tabOptions">
            <div className="minimize mt-2">
              <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow} alt=""></img>
            </div>
            <div>
              <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                Incidents
              </button>
            </div>
          </div>
          <div className="tabContent">
            {activeTab === "Incidents" && (
              <div className="d-block mx-2">
                <iframe src={httpContext.sop} title="Sop" className="iframetabs"></iframe>
              </div>
            )}
          </div>
        </div>
      )}
      {label === "AccidentTabs" && (
        <div className="tabs">
          <div className="tabOptions">
            <div className="minimize mt-2">
              <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow} alt=""></img>
            </div>
            <div>
              <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                Incidents
              </button>
            </div>
          </div>
          <div className="tabContent">
            {activeTab === "Incidents" && (
              <div className="d-block mx-2">
                <iframe src={httpContext.sop} title="Sop" className="iframetabs"></iframe>
              </div>
            )}
          </div>
        </div>
      )}
      {label === "AqiTabs" && (
        <div className="tabs">
          <div className="tabOptions">
            <div className="minimize mt-2">
              <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow} alt=""></img>
            </div>
            <div>
              <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                Incidents
              </button>
            </div>
          </div>
          <div className="tabContent">
            {activeTab === "Incidents" && (
              <div className="d-block mx-2">
                <iframe src={httpContext.sop} title="Sop" className="iframetabs"></iframe>
              </div>
            )}
          </div>
        </div>
      )}
      {label === "bearing" && (
        <div className="frameWindow w-100">
          <div className="tabs">
            <div className="tabOptions mb-2">
              <div>
                <button onClick={() => setActiveTab("Incidents")} className={`tabBtns ${activeTab === "Incidents" ? "active" : null}`}>
                  Incidents
                </button>
              </div>
              {/* <div>
                <button onClick={() => setActiveTab("analytics")} className={`tabBtns ${activeTab === "analytics" ? "active" : null}`}>
                  Analytics
                </button>
              </div> */}
              <div>
                <button onClick={() => setActiveTab("advisory")} className={`tabBtns ${activeTab === "advisory" ? "active" : null}`}>
                  Advisory
                </button>
              </div>
              {/* <div>
                <button onClick={() => setActiveTab("realtime")} className={`tabBtns ${activeTab === "realtime" ? "active" : null}`}>
                  Realtime
                </button>
              </div> */}

              <div className="minimize mt-2">
                <img src="/minimize-s.png" className="mini-traf" onClick={closeWindow} alt=""></img>
              </div>
            </div>
            <div className="tabContent ">
              {/* {activeTab === "analytics" && (
                <div className="row">
                  {subsystems.bms.ahuanalytics.leftpanel.map((cd) => (
                    <div className="col-4">
                      <LineHighChart data={cd} style={chartStyle}></LineHighChart>
                    </div>
                  ))}
                </div>
              )} */}
              {activeTab === "advisory" && (
                <div className="p-1 ">
                  <table className="d-block text-light table px-0" style={{ height: "90vh" }}>
                    <tr className="d-flex justify-content-between  align-items-baseline pr_1rem ">
                      <th className="col-11 fs-6 px-4">Observation</th>
                      <th className="col-auto px-0 fs-6">Action</th>
                    </tr>
                    {bearingData.map(
                      (adv, i) =>
                        commonContext.bearingIncident && (
                          <tr key={adv.id} className="d-flex justify-content-between px-4">
                            <td className="col-10 text-justify py-1 border-0 text fs-7">
                              {adv.observation}
                              <span className="span-color">{adv?.assetId}</span>
                            </td>
                            <td>
                              <button
                                key={adv.id}
                                className=" col-auto myBtns text-white cursor-pointer"
                                onClick={(event) => handleSop(event, adv.id)}
                              >
                                ACT
                              </button>
                            </td>
                          </tr>
                        )
                    )}
                  </table>
                </div>
              )}
              {activeTab === "Incidents" && (
                <div style={{ height: "90vh" }}>
                  <iframe src={httpContext.sop} title="Sop" style={{ height: "100%", width: "100%" }}></iframe>
                </div>
              )}
              {/* {activeTab === "realtime" && (
                <div className=" realtimeCard p-1" style={{ height: "90vh" }}>
                  {subsystems.bms.ahuanalytics.realTimeData.description.map((item, index) => (
                    <div key={index} className="text-light fs-7 text-center m-2 realtimeContent p-1">
                      <div className="fs-5 fw-bold  mt-4 p-4">{item.title}</div>
                      <div className="row">
                        {item.details.map((val, idx) => (
                          <div key={idx} className="col-6 mt-3">
                            <div>
                              <span className="fs-5 fw-bold">{val.title} : </span>
                              <span className="fs-6">
                                &nbsp;
                                {val.value ? (
                                  val.value === "random" ? (
                                    <input
                                      className={`cursor-pointer ${httpContext.incident ? "randomRat" : "random-none"}`}
                                      id="RAT"
                                      readOnly
                                      value={commonContext.inputFieldValue}
                                    />
                                  ) : (
                                    val.value
                                  )
                                ) : null}
                              </span>
                            </div>

                            {val.details && val.details.length > 0 && (
                              <div className="nested-details d-flex flex-row mx-2 mt-4">
                                {val.details.map((nestedVal, nestedIdx) => (
                                  <div key={nestedIdx} className="d-flex flex-column col-6 ">
                                    <div className="d-flex flex-column mx-2 ">
                                      <div className="fs-5 fw-bold">{nestedVal.title}</div>
                                      <div className="fs-6">
                                        &nbsp;
                                        {nestedVal.value ? (
                                          nestedVal.value === "random" ? (
                                            <input
                                              className={`cursor-pointer ${httpContext.incident ? "randomRat" : "random-none"}`}
                                              id="RAT"
                                              readOnly
                                              value={commonContext.inputFieldValue}
                                            />
                                          ) : (
                                            nestedVal.value
                                          )
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )} */}
              {showAdv && <AdvisoryModel showIssue={showIssue} closeadv={() => setShowAdv(false)} sopTab={sopTab}></AdvisoryModel>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboardtabs;
