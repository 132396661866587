/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Map } from "react-map-gl/maplibre";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import DeckGL from "@deck.gl/react";
import { IconLayer, PolygonLayer } from "@deck.gl/layers";
import { TripsLayer } from "@deck.gl/geo-layers";
import { animate } from "popmotion";
import data from "../../assets/building.json";

const DATA_URL = {
  BUILDINGS: data,
};

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0,
});

const pointLight = new PointLight({
  color: [255, 255, 255],
  intensity: 2.0,
  position: [-74.05, 40.7, 8000],
});

const lightingEffect = new LightingEffect({ ambientLight, pointLight });

const DEFAULT_THEME = {
  buildingColor: [74, 80, 87],
  trailColor0: [253, 128, 93],
  trailColor1: [23, 184, 190],
  material: {
    ambient: 0.1,
    diffuse: 0.6,
    shininess: 32,
    specularColor: [60, 64, 70],
  },
  effects: [lightingEffect],
};

const INITIAL_VIEW_STATE = {
  longitude: -74.01091,
  latitude: 40.70686,
  zoom: 14,
  pitch: 45,
  bearing: 0,
};

const MAP_STYLE = "https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json";

const landCover = [
  [
    [-74.0, 40.7],
    [-74.02, 40.7],
    [-74.02, 40.72],
    [-74.0, 40.72],
  ],
];

export default function App({
  buildings = DATA_URL.BUILDINGS,
  trips = DATA_URL.TRIPS,
  trailLength = 200,
  initialViewState = INITIAL_VIEW_STATE,
  mapStyle = MAP_STYLE,
  theme = DEFAULT_THEME,
  loopLength = 1800,
  animationSpeed = 1,
}) {
  const [time, setTime] = useState(0);
  const [selectedBuildingId, setSelectedBuildingId] = useState(null);
  const [ahuIncident, setAhuIncident] = useState(false);
  const [bearingIncident, setBearingIncident] = useState(false);

  const [showPop, setShowPop] = useState(false);
  const [popupInfo, setPopupInfo] = useState(null);
  const [layer, setLayer] = useState();

  window.addEventListener("message", (event) => {
    if (event.data.ahustate) {
      setAhuIncident(event.data.ahustate);
    }
    if (event.data.bearingState) {
      setBearingIncident(event.data.bearingState);
    }
  });
  function getFillColor(buildingId, theme) {
    const defaultColor = theme.buildingColor;

    if ((ahuIncident || bearingIncident) && buildingId === "T07") {
      return [255, 0, 0];
    } else {
      return defaultColor;
    }
  }
  useEffect(() => {
    const animation = animate({
      from: 0,
      to: loopLength,
      duration: (loopLength * 10) / animationSpeed,
      repeat: Infinity,
      onUpdate: setTime,
    });
    return () => animation.stop();
  }, [loopLength, animationSpeed]);
  function getTooltip({ object }) {
    return (
      object && {
        html: `<div  style={
             
              color: "white",
            }>${object.id}</div>
            `,
        capturePointerMove: true,
      }
    );
  }
  useEffect(() => {
    let layers = [
      new PolygonLayer({
        id: "ground",
        data: landCover,
        getPolygon: (f) => f,
        stroked: false,
        getFillColor: [0, 0, 0, 0],
      }),
      new TripsLayer({
        id: "trips",
        data: trips,
        getPath: (d) => d.path,
        getTimestamps: (d) => d.timestamps,
        getColor: (d) => (d.vendor === 0 ? theme.trailColor0 : theme.trailColor1),
        opacity: 0.3,
        widthMinPixels: 2,
        rounded: true,
        trailLength,
        currentTime: time,
        shadowEnabled: false,
      }),
      new PolygonLayer({
        id: "buildings",
        data: buildings,
        extruded: true,
        wireframe: false,
        opacity: 0.5,
        getPolygon: (f) => f.polygon,
        getElevation: (f) => f.height,
        onClick: (info) => {
          if (info.object) {
            setShowPop(true);
            setPopupInfo(info.object);
            setSelectedBuildingId(info.object.id);
            let data = info.object.id;
            window.parent.postMessage(data, "*");
          }
        },
        getFillColor: (f) => getFillColor(f.id, theme),
        updateTriggers: {
          getFillColor: [selectedBuildingId, ahuIncident, bearingIncident],
          getFillColor,
        },
        material: theme.material,
        pickable: true,
        onHover: (f) => getTooltip(f.picked),
      }),
      new IconLayer({
        id: "IconLayer",
        data: data,
        getColor: (f) => [Math.sqrt(f.exits), 140, 0],
        getIcon: (f) => "marker",
        getPosition: (f, i) => f.polygon[i],
        getSize: 40,
        iconAtlas: "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png",
        iconMapping: "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.json",
        pickable: true,
      }),
    ];
    setLayer(layers);
  }, [layer]);

  function handleClick() {
    if (window.parent.location.pathname === "/" || window.parent.location.pathname === "/overview") {
      let googledata = { message: "navigate T07" };

      window.parent.postMessage(googledata, "*");
    } else if (window.parent.location.pathname === "/alarm") {
      let googledata = { message: "Launch Digital Twin" };
      window.parent.postMessage(googledata, "*");
    }
  }

  function reset() {
    let data = "";
    window.parent.postMessage(data, "*");
    setShowPop(false);
  }
  return (
    <div className="mapContainer" style={{ height: "100vh", width: "100vw", position: "relative" }}>
      <DeckGL layers={layer} effects={theme.effects} initialViewState={initialViewState} controller={true} getTooltip={getTooltip}>
        <Map reuseMaps mapStyle={mapStyle} />
      </DeckGL>

      {showPop && (
        <>
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              color: "white",
              background: "black",
              padding: "15px",
              borderRadius: "5px",
              left: "1rem",
              marginTop: "2.5rem",
            }}
          >
            <div className="text-center fs-6">{popupInfo.id}</div>
            {popupInfo.id === "T07" && (
              <div>
                <button type="button" class="btn btn-light fs-7 font-weight-bold" value="" onClick={handleClick}>
                  Launch Digital Twin
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          padding: "10px",
          borderRadius: "5px",
          // pointerEvents: "none",
          opacity: 1,
          left: "10px",
          top: "0px",
        }}
      >
        <div className="home">
          <img src={require("../../assets/icons/home.svg").default} style={{ height: "1rem" }} onClick={reset} alt=""></img>
        </div>
      </div>
    </div>
  );
}
