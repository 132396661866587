import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles/styles.scss";
import Home from "./components/bms/Home";
import Traffic from "./components/traffic/traffic";
import Trips from "./components/traffic/trips";
import Analytics from "./components/bms/analytics";
import Model from "./components/model/model";
import AhuAnalytics from "./components/bms/ahuAnalytics";
import Delta from "./components/bms/delta";
import Accident from "./components/traffic/accidents/accident";
import Gmap from "./components/bms/gmap.jsx";
import Violation from "./components/traffic/violation/violation.jsx";
import Simulation from "./components/simulation_control/simulation.jsx";
import Aqi from "./components/environment/aqi.jsx";
import SubAnalytics from "../src/components/bms/subAnalytics.jsx";
import Map from "../src/components/bms/3dmaps.jsx";
import BmsOverview from "./components/bms/bmsOverview.jsx";
import Livabilityindex from "./components/livability_index/livabilityindex.jsx";
import Leftpanel from "./components/cwpleftpanel/leftpanel.jsx";
import Bearing from "./components/bms/bearing.jsx";
import ServerModel from "./components/model/serverModel.jsx";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<BmsOverview />} />
          <Route path="/overview" element={<BmsOverview />} />
          <Route path="/alarm" element={<Home />} />
          <Route path="/traffic" element={<Traffic />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/trips" element={<Trips />} />
          <Route path="/model" element={<Model />} />
          <Route path="/delta" element={<Delta />} />
          <Route path="/ahu-analytics" element={<AhuAnalytics />} />
          <Route path="/accident" element={<Accident />} />
          <Route path="/overspeed" element={<Violation />} />
          <Route path="/signaljump" element={<Violation />}></Route>
          <Route path="/wronglane" element={<Violation />}></Route>
          <Route path="/gmaps" element={<Gmap />}></Route>
          <Route path="/simulation" element={<Simulation />}></Route>
          <Route path="/aqi" element={<Aqi />}></Route>
          <Route path="/subanalytics" element={<SubAnalytics />}></Route>
          <Route path="/3dmaps" element={<Map />}></Route>
          <Route path="/livabilityindex" element={<Livabilityindex />}></Route>
          <Route path="/server" element={<ServerModel />}></Route>
          <Route path="/bearing" element={<Bearing />}></Route>
          <Route path="/leftpanel/*" element={<Leftpanel />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
