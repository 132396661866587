/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext, useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { useGLTF, Text } from "@react-three/drei";
import modelDetail from "../../assets/modelDetail.json";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent, TooltipComponent, VisualMapComponent } from "echarts/components";
import { HeatmapChart } from "echarts/charts";
import data1 from "../../assets/serverData1.json";
import data2 from "../../assets/serverData2.json";
import data3 from "../../assets/serverData3.json";
import data4 from "../../assets/serverData4.json";
import data5 from "../../assets/serverData5.json";
import data6 from "../../assets/serverData6.json";

import GUI from "lil-gui";
import * as THREE from "three";
import { ModelServiceContext } from "../../config/modelContext";
import ServerPanel from "./serverPanel";

echarts.use([CanvasRenderer, GridComponent, TooltipComponent, VisualMapComponent, HeatmapChart]);
const ServerModel = () => {
  const [modelState, setModelState] = useState({
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    scale: { x: 1, y: 1, z: 1 },
    wireframe: false,
    autoRotate: false,
    backgroundColor: "hsla(219, 59%, 22%, 1)",
    showAxes: false,
    showHeatmap: false,
  });
  const [isGUIVisible, setIsGUIVisible] = useState(true);
  const [imageYPosition, setImageYPosition] = useState(0.5);

  const axesRef = useRef();
  const guiRef = useRef(null);
  const modelRef = useRef();
  const planeRef = useRef();
  const heatmapCanvas = useRef(null);

  const modelDetails = modelDetail["server"];
  const { scene } = useGLTF("/server_room.glb");
  const modelContext = useContext(ModelServiceContext);

  useEffect(() => {
    if (planeRef.current) {
      planeRef.current.position.y = imageYPosition;
    }
  }, [imageYPosition]);

  useEffect(() => {
    if (imageYPosition > 0 && imageYPosition < 1) {
      let data = JSON.parse(JSON.stringify(data1));
      if (data) heatmapCanvas.current = createHeatmapTexture(data);
    } else if (imageYPosition > 1 && imageYPosition <= 2) {
      let data = JSON.parse(JSON.stringify(data2));
      if (data) heatmapCanvas.current = createHeatmapTexture(data);
    } else if (imageYPosition > 2 && imageYPosition <= 3) {
      let data = JSON.parse(JSON.stringify(data3));
      if (data) heatmapCanvas.current = createHeatmapTexture(data);
    } else if (imageYPosition > 3 && imageYPosition <= 4) {
      let data = JSON.parse(JSON.stringify(data4));
      heatmapCanvas.current = createHeatmapTexture(data);
    } else if (imageYPosition > 4 && imageYPosition <= 5) {
      let data = JSON.parse(JSON.stringify(data5));
      heatmapCanvas.current = createHeatmapTexture(data);
    } else if (imageYPosition > 3 && imageYPosition <= 6) {
      let data = JSON.parse(JSON.stringify(data6));
      heatmapCanvas.current = createHeatmapTexture(data);
    }
  }, [imageYPosition]);

  useEffect(() => {
    modelContext.setObject(scene);
  }, [scene.children.length]);

  const meshClick = (event) => {
    console.log(event.object.name);
    modelDetails?.materialIds.forEach((ele) => {
      if (ele.id === event.object.name) {
        modelContext.navigate(ele.x, ele.y, ele.z, event.object.name, "detailView");
      }
    });
  };

  useEffect(() => {
    modelContext.setObject(scene);
    if (!guiRef.current) {
      const gui = new GUI({ width: 300, title: "Model Debug UI" });
      guiRef.current = gui;

      const positionFolder = gui.addFolder("Position");
      positionFolder.close();
      positionFolder
        .add(modelState.position, "x", -100, 100, 0.1)
        .name("Position X")
        .onChange((val) => {
          if (modelRef.current) {
            modelRef.current.position.x = val;
          }
          setModelState((prev) => ({ ...prev, position: { ...prev.position, x: val } }));
        })
        .listen();

      positionFolder
        .add(modelState.position, "y", -100, 100, 0.1)
        .name("Position Y")
        .onChange((val) => {
          if (modelRef.current) {
            modelRef.current.position.y = val;
          }
          setModelState((prev) => ({ ...prev, position: { ...prev.position, y: val } }));
        })
        .listen();

      positionFolder
        .add(modelState.position, "z", -100, 100, 0.1)
        .name("Position Z")
        .onChange((val) => {
          if (modelRef.current) {
            modelRef.current.position.z = val;
          }
          setModelState((prev) => ({ ...prev, position: { ...prev.position, z: val } }));
        })
        .listen();
      const displayFolder = gui.addFolder("Display");
      displayFolder.close();

      displayFolder
        .add(modelState, "wireframe")
        .name("Wireframe")
        .onChange((val) => {
          scene.traverse((child) => {
            if (child.isMesh && child.material) {
              child.material.wireframe = val;
            }
          });
          setModelState((prev) => ({ ...prev, wireframe: val }));
        });

      displayFolder
        .add(modelState, "autoRotate")
        .name("Auto Rotate")
        .onChange((val) => {
          setModelState((prev) => ({ ...prev, autoRotate: val }));
        });

      displayFolder
        .addColor(modelState, "backgroundColor")
        .name("Background Color")
        .onChange((color) => {
          setModelState((prev) => ({ ...prev, backgroundColor: color }));
        });
      displayFolder
        .add(modelState, "showAxes")
        .name("Show Axes Helper")
        .onChange((val) => {
          setModelState((prev) => ({ ...prev, showAxes: val }));
        });

      const imageFolder = gui.addFolder("Heatmap Position");
      imageFolder
        .add(modelState, "showHeatmap")
        .name("Show Heatmap")
        .onChange((val) => {
          setModelState((prev) => ({ ...prev, showHeatmap: val }));
        });
      imageFolder
        .add({ y: imageYPosition }, "y", 0.4, 12, 0.1)
        .name("Heatmap Y Position")
        .onChange((val) => {
          setImageYPosition(val);
        });
    }
    const toggleGUI = (event) => {
      if (event.key === "h" && guiRef.current) {
        setIsGUIVisible((prev) => !prev);
      }
    };

    window.addEventListener("keydown", toggleGUI);
    return () => {
      if (guiRef.current) {
        guiRef.current.destroy();
        guiRef.current = null;
      }
      window.removeEventListener("keydown", toggleGUI);
    };
  }, [scene]);

  useEffect(() => {
    if (guiRef.current) {
      guiRef.current.show(isGUIVisible);
    }
  }, [isGUIVisible]);

  useEffect(() => {
    if (scene) {
      scene.position.set(modelState.position.x, modelState.position.y, modelState.position.z);
      scene.rotation.set(modelState.rotation.x, modelState.rotation.y, modelState.rotation.z);
      scene.scale.set(modelState.scale.x, modelState.scale.y, modelState.scale.z);
    }
  }, [modelState.position, modelState.rotation, modelState.scale, scene]);

  return (
    <div
      className=" p-2 position-relative h-100"
      style={{
        background: "linear-gradient(180deg, hsla(202, 87%, 53%, 0.16) 0%, hsla(237, 61%, 60%, 0.16) 100%)",
      }}
    >
      <Canvas
        camera={{ position: modelDetails.camera.position }}
        style={{
          background: modelState.backgroundColor,
          width: window.innerWidth,
          height: window.innerHeight,
          top: 0,
          left: 0,
          position: "fixed",
        }}
      >
        <ambientLight intensity={0.8} />
        <pointLight intensity={3.5} position={[1, 1, 0]} />
        <pointLight intensity={3.5} position={[-1, 1, 0]} />
        <pointLight intensity={0.5} position={[1, -1, 0]} />
        <OrbitControls ref={modelContext.controls} target={modelDetails.target} autoRotate={modelState.autoRotate} autoRotateSpeed={3.0} />
        <directionalLight intensity={2.5} />
        {modelState.showAxes && <axesHelper args={[35]} />}
        <Suspense>
          {scene.children.length && (
            <primitive
              ref={modelRef}
              object={scene}
              position={[modelState.position.x, modelState.position.y, modelState.position.z]}
              rotation={[modelState.rotation.x, modelState.rotation.y, modelState.rotation.z]}
              scale={[modelState.scale.x, modelState.scale.y, modelState.scale.z]}
              onClick={(event) => meshClick(event)}
            />
          )}
          {modelState.showHeatmap && (
            <mesh ref={planeRef} position={[-23, imageYPosition, -6]} rotation={[-Math.PI / 2, 0, 0]}>
              <planeGeometry args={[78, 77, 1, 1]} />
              <meshBasicMaterial
                map={heatmapCanvas.current ? new THREE.CanvasTexture(heatmapCanvas.current) : null}
                transparent={true}
                opacity={1}
              ></meshBasicMaterial>
            </mesh>
          )}
        </Suspense>
      </Canvas>
      <div className="position-absolute p-1 w-100">
        <div className="p-1 fs-6 d-flex justify-content-center fc-white">Data Center Temperature Simulation - Time Series Forecast Model</div>
      </div>
      <ServerPanel />
    </div>
  );
};

useGLTF.preload("/server_room.glb");
export default ServerModel;

const createHeatmapTexture = (data) => {
  const canvas = document.createElement("canvas");
  canvas.width = 512;
  canvas.height = 512;
  const ctx = canvas.getContext("2d");
  if (echarts.getInstanceByDom(canvas)) {
    echarts.dispose(canvas);
  }
  ctx.clearRect(0, 0, 512, 512);

  // Initialize the chart instance on the canvas
  const chart = echarts.init(canvas, null, { renderer: "canvas" });

  const option = {
    tooltip: {},
    xAxis: {
      type: "category",
      data: data.xData,
    },
    yAxis: {
      type: "category",
      data: data.yData,
    },
    grid: { height: "100%", top: "0%", left: "0%", right: "0%" },
    visualMap: {
      show: false,
      min: 0,
      max: 1.3,
      inRange: {
        color: ["#00FF00", "#32CD32", "#7FFF00", "#ADFF2F", "#FFFF00", "#FFD700", "#FFA500", "#FF8C00", "#FF4500", "#FF0000", "#B22222"],
      },
    },
    series: [
      {
        name: "Heatmap",
        type: "heatmap",
        data: data.data,
        emphasis: {
          itemStyle: {
            borderColor: "#333",
            borderWidth: 1,
          },
        },
        progressive: 1000,
        animation: false,
      },
    ],
  };

  chart.setOption(option);
  return canvas;
};
